import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ShopSidebar from "./ShopSidebar";
import * as ShopSelector from "../../store/Shop/selectors";
import { useEffect } from "react";
import {
  getShopBuilds,
  getShopCategories,
  getShopMachine,
  getShopModelGroup,
  getShopModelGroupList,
  getShopScheme,
  getShopSchemeItems,
  setShopBuilds,
  setShopCategories,
  setShopCategory,
  setShopScheme,
  setShopSchemeItems,
  setShopSubCategory,
  setShopSubSubCategory,
} from "../../store/Shop/actions";
import Crumbs from "./Crumbs";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CartModal from "./CartModal";
import * as MachineSelector from "../../store/Machine/selectors";
import {
  getMachine,
  getMachineOwner,
  setMachine,
} from "../../store/Machine/actions";
import Preloader from "../../components/Preloader";
import ImageMagnifier from "./ImageMagnifier";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import * as AuthSelector from "../../store/Auth/selectors";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { formatPrice } from "../../utils/price";

const Scheme = () => {
  const { nomer, cat, sub, part, scheme, model } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const machine = useSelector(MachineSelector.machine);
  const categories = useSelector(ShopSelector.categories);
  const subcategory = useSelector(ShopSelector.subcategory);
  const schemeData = useSelector(ShopSelector.scheme);
  const category = useSelector(ShopSelector.category);
  const schemeItems = useSelector(ShopSelector.schemeItems);
  const model_group = useSelector(ShopSelector.model_group);
  const machineOwner = useSelector(MachineSelector.machineOwner);
  const shopModelGrouoList = useSelector(ShopSelector.shopModelGrouoList);

  const auth = useSelector(AuthSelector.auth);
  const user = useSelector(AuthSelector.user);

  const [modal, setModal] = useState(false);
  const [cartItem, setCartItem] = useState(null);
  const [zoom, setZoom] = useState(false);
  const [show, setShow] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    dispatch(getMachineOwner(user.company_id));
    if (nomer) {
      dispatch(getMachine(nomer));
    }

    return () => {
      dispatch(setMachine([]));
      dispatch(setShopCategory(null));
      dispatch(setShopSubCategory(null));
      dispatch(setShopCategories([]));
      dispatch(setShopBuilds([]));
      dispatch(setShopScheme([]));
      dispatch(setShopSchemeItems([]));
    };
  }, [nomer]);

  useEffect(() => {
    if (!machine.length && machine.nomer === parseInt(nomer)) {
      dispatch(getShopModelGroupList());
      dispatch(
        getShopModelGroup(
          machine.real_model_id !== null
            ? machine.real_model_id
            : machine.model_id
        )
      );
    }
  }, [machine]);

  useEffect(() => {
    if (typeof model_group.group_id !== "undefined") {
      dispatch(getShopBuilds(model_group.group_id, part));
      if (categories.length === 0) {
        dispatch(
          getShopCategories(
            shopModelGrouoList.filter(
              (item) => item.id === model_group.group_id
            )[0].machine_type_id,
            model_group.group_id
          )
        );
      }
    }

    if (model) {
      dispatch(getShopCategories(1, parseInt(model)));
      dispatch(getShopBuilds(parseInt(model), part));
    }
  }, [model_group, shopModelGrouoList, model]);

  useEffect(() => {
    if (categories.length !== 0) {
      const itemCat = categories.find((item) => item.id === parseInt(cat));
      dispatch(setShopCategory(itemCat));
    }
  }, [categories]);

  useEffect(() => {
    if (category) {
      const itemSub = category.children.find(
        (item) => item.id === parseInt(sub)
      );
      dispatch(setShopSubCategory(itemSub));
    }
  }, [category]);

  useEffect(() => {
    if (category) {
      const itemSub = subcategory.children.find(
        (item) => item.id === parseInt(part)
      );
      dispatch(setShopSubSubCategory(itemSub));
    }
  }, [subcategory]);

  useEffect(() => {
    if (typeof scheme !== "undefined") {
      dispatch(getShopScheme(scheme));
      dispatch(getShopSchemeItems(scheme));
    }
  }, [scheme]);

  const handlerCart = (item) => {
    setModal(true);
    setCartItem(item);
  };

  const Point = ({ coords, item }) => {
    return (
      <Box
        onClick={() => handlerCart(item)}
        sx={{
          position: "absolute",
          backgroundColor: "#1976d2",
          width: 22,
          height: 22,
          fontSize: 12,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "4px",
          color: "#fff",
          fontWeight: "400",
          left: `${coords.x}%`,
          top: `${coords.y}%`,
          cursor: "pointer",
        }}
      >
        {item.nomer}
      </Box>
    );
  };

  const Number = ({ item }) => {
    return item.cords.map((cord, i) => (
      <Point key={i} coords={cord} item={item} />
    ));
  };

  const Points = ({ items }) => {
    return items.map((e) => <Number key={e.id} item={e} />);
  };

  if (categories.length === 0) return <Preloader />;

  return (
    <Box
      sx={{
        p: "30px",
        flex: 1,
      }}
    >
      {!model && <Crumbs machine={machine} />}
      <Grid container spacing={3} sx={{ height: "100%" }}>
        <Grid item xs={2}>
          <ShopSidebar b={true} o={true} auth={auth} />
        </Grid>
        <Grid item xs={10} sx={{ height: "100%" }}>
          <Box display="flex" sx={{ height: "100%" }}>
            <Box sx={{ mr: 1 }}>
              <Typography sx={{ position: "relative" }}>
                Все права защищены{" "}
                <IconButton onClick={() => setShow(true)} color="primary">
                  <InfoOutlinedIcon />
                </IconButton>
              </Typography>
              <p
                style={{
                  maxWidth: 700,
                  fontSize: "14px",
                  textAlign: "justify",
                  marginBottom: 0,
                }}
              >
                Обращаем ваше внимание на то, что данный интернет-сайт, а также
                вся информация о товарах и ценах, предоставленная на нём, носит
                исключительно информационный характер и ни при каких условиях не
                является публичной офертой.
              </p>
              {schemeData && (
                <Box sx={{ width: 700, height: 700, position: "relative" }}>
                  <ImageMagnifier
                    src={`http://shop.dstapi.ru/${schemeData.image}`}
                    width={"100%"}
                    height={"100%"}
                    magnifierHeight="250"
                    magnifieWidth="250"
                    zoomLevel="2"
                    active={zoom}
                  />
                  {!zoom ? <Points items={schemeItems} /> : null}
                  <IconButton
                    color="primary"
                    onClick={() => setZoom(!zoom)}
                    sx={{
                      position: "absolute",
                      top: "15px",
                      right: "15px",
                      width: "60px",
                      height: "60px",
                    }}
                  >
                    {!zoom ? (
                      <ZoomInIcon sx={{ fontSize: "30px" }} />
                    ) : (
                      <ZoomOutIcon sx={{ fontSize: "30px" }} />
                    )}
                  </IconButton>
                </Box>
              )}
            </Box>
            <Box>
              <Box
                sx={{
                  height: "100%",
                  overflowY: "scroll",
                  "::-webkit-scrollbar": {
                    width: "6px",
                  },
                  "::-webkit-scrollbar-track": {
                    background: "#fff",
                  },
                  "::-webkit-scrollbar-thumb": {
                    backgroundColor: "#1976d2",
                    borderRadius: "20px",
                    border: "none",
                  },
                }}
              >
                <Typography sx={{ position: "relative" }}>
                  Не нашли необходимые запасные части?{" "}
                  <IconButton onClick={() => setShowInfo(true)} color="primary">
                    <InfoOutlinedIcon />
                  </IconButton>
                </Typography>
                <TableContainer
                  sx={{
                    mt: 2,
                  }}
                  component={Paper}
                >
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>№</TableCell>
                        <TableCell>Деталь</TableCell>
                        <TableCell>Артикул</TableCell>
                        <TableCell align="left">Цена, руб</TableCell>
                        <TableCell align="left">Наличие</TableCell>
                        <TableCell align="left">Вес, кг</TableCell>
                        <TableCell align="left"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {schemeItems.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{row.nomer}</TableCell>
                          <TableCell
                            sx={{
                              maxWidth: "250px",
                            }}
                            component="th"
                            scope="row"
                          >
                            {row.title === "" ? row.shop.title : row.title}
                          </TableCell>
                          <TableCell>{row.shop.full_code}</TableCell>
                          <TableCell align="left">
                            {parseInt(row.e_com.price) !== 0
                              ? machineOwner.sale
                                ? formatPrice(
                                    (row.e_com.price / 100) *
                                      (100 - machineOwner.sale)
                                  )
                                : formatPrice(row.e_com.price)
                              : "По запросу"}
                          </TableCell>
                          <TableCell align="left">
                            {parseInt(row.e_com.price) !== 0 &&
                            parseInt(row.e_com.count) !== 0
                              ? row.e_com.count
                              : "Под заказ"}
                          </TableCell>
                          <TableCell align="left">
                            {row.e_com.weight !== 0 ? row.e_com.weight : "-"}
                          </TableCell>
                          <TableCell align="left">
                            <IconButton
                              color="primary"
                              onClick={() => handlerCart(row)}
                              aria-label="cart"
                            >
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {modal && cartItem ? (
        <CartModal
          open={modal}
          onClose={setModal}
          item={cartItem}
          machine={machine.nomer}
          sale={machineOwner.sale}
        />
      ) : null}
      <Dialog onClose={() => setShowInfo(false)} open={showInfo}>
        <Box
          sx={{
            width: "500px",
            background: "#fff",
            padding: 4,
            borderRadius: "4px",
            fontSize: "16px",
          }}
        >
          Для заказа нужной позиции напишите название необходимой детали в
          комментарии к заказу.
          <br />
          <br />
          Предварительно убедитесь в том, что данная деталь отсутствует в
          разделах интернет-площадки.
        </Box>
      </Dialog>
      <Dialog onClose={() => setShow(false)} open={show}>
        <Box
          sx={{
            width: "500px",
            zIndex: 999,
            background: "#fff",
            padding: 4,
            borderRadius: "4px",
            fontSize: "16px",
          }}
        >
          Любое копирование и воспроизведение текста, в том числе частичное и в
          любых формах, без письменного разрешения правообладателя запрещено.
          <br />
          <br />
          Любое использование фото, аудио, видео и графических материалов, в том
          числе частичное, без письменного разрешения правообладателя запрещено.
          <br />
          <br />
          Все материалы сайта monitor.tm10.ru, любая текстовая, графическая или
          видео информация, а также структура сайта и оформление страниц,
          защищены российским и международным законодательством в соответствии с
          соглашением об охране авторских прав, смежных прав и интеллектуальной
          собственности (ст. № 1259, 1260, главы 70 «Авторское право»
          Гражданского Кодекса Российской Федерации от 18.12.2006 года №
          230-ФЗ).
          <br />
          <br />
          Лица, виновные в нарушении авторских прав, смежных и исключительных
          прав на использование текстовых, фото, аудио, видео и графических
          материалов несут гражданско-правовую, административную и уголовную
          ответственность в соответствии с законодательством Российской
          Федерации.
        </Box>
      </Dialog>
    </Box>
  );
};

export default Scheme;
